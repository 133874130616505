import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import cn from 'classnames';
import { getContentPath } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  customLink: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginRight: '10px',
    color: theme.palette.titleClr,
    '&:hover': {
      color: theme.palette.titleClr,
    },
    '&:visited': {
      color: theme.palette.titleClr,
    },
  },
  containerClassStyleWithScroll: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '5px'
  },
  containerClassStyleWithoutScroll: {
    position: 'fixed',
    bottom: '0',
    left: '50%',
    paddingBottom: '5px'
  },
  dotSeparatorStyle: {
    marginTop: '-5px',
    fontWeight: 'bold',
    color: theme.palette.titleClr
  },
}));

const FooterComponent = () => {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:1024px)');
  const [bottom, setBottom] = useState(1100);
  const [scroll, setScroll] = useState(false);
  const privacyPolicyUrl = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.privacyPolicyUrl;

  useEffect(() => {
    if (window.innerHeight < document.body.scrollHeight) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  });

  const handleScroll = () => {
    setBottom(document.body.scrollHeight - 90);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    if ('scrollRestoration' in window.history) {
      // Back off, browser, I got this...
      window.history.scrollRestoration = 'manual';
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    // eslint-disable-next-line no-nested-ternary
    <Grid container id="footerLink" className={cn(scroll ? classes.containerClassStyleWithScroll : (window.location.pathname !== '/profile' && classes.containerClassStyleWithoutScroll))} style={{ position: mobileView && 'absolute', top: (mobileView && window.location.hostname !== 'celebrity.sakhaglobal.com') && `${bottom}px` }}>
      <Link id="termsLink" className={classes.customLink} href={getContentPath('/termsAndCondition')}>
        Terms and Conditions
      </Link>
      <span className={classes.dotSeparatorStyle}>.</span>
      <Link
        id="policyLink"
        className={classes.customLink}
        target="_blank"
        href={privacyPolicyUrl}
      >
        Privacy Policy
      </Link>
    </Grid>
  );
};

export default FooterComponent;
