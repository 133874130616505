import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Card,
  CardContent,
  CardMedia, Typography, useMediaQuery
} from '@material-ui/core';
import cn from 'classnames';
import { getContentPath, ellipsis } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  aboutDiv: {
    padding: '20px',
    background: 'linear-gradient(89.88deg, #111622 0.09%, #223F7A 99.89%)',
    borderRadius: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '30px',
    '@media (max-width:1023px)': {
      textAlign: 'left',
    },
  },
  aboutTitle: {
    color: theme.palette.titleClr,
    fontSize: '1.375rem'
  },
  aboutDesc: {
    color: theme.palette.titleClr,
    fontSize: '0.9rem',
    opacity: '60%'
  },
  cardImage: {
    backgroundSize: 'contain',
    objectFit: 'contain',
  },
  cardImageBlur: {
    top: '0px',
    height: '250px',
    background: 'linear-gradient(89.88deg, #111622 0.09%, #223F7A 99.89%)',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      height: '110px',
      padding: '0px',
    },
  },
  cardDiv: {
    // marginTop: 40,
    overflow: 'scroll',
    // marginRight: '60px',
    [theme.breakpoints.down('md')]: {
      marginRight: '0px',
      marginTop: 0,
    },
  },
  containerDiv: {
    '@media (max-width:1303px)': {
      marginTop: '14%'
    },
    '@media (min-width:1025px)': {
      marginTop: '7%'
    },
    '@media (max-width:767px)': {
      marginTop: '19%'
    },

  }
}));

const MotherPortalDashboard = (props) => {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:1024px)');

  const redirectToArtistDomain = (domain) => {
    window.location.href = `https://${domain}`
  }

  const renderArtistList = (artistData) => {
    return (
      artistData?.artistList?.map((artist) => {
        return (
          <>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={3}
              className={classes.cardDiv}
            >
              <Card
                style={{
                  cursor: 'pointer',
                  position: 'relative',
                  border: '1px solid #132C5F',
                  borderRadius: '15px',
                  boxShadow: '0px 2px 7px 0px #37526B4D',
                  background: '#141B2E'
                }}
                onClick={() => redirectToArtistDomain(artist.domain)}
              >
                <CardMedia
                  className={cn(
                    classes.cardImage,
                    classes.cardImageBlur
                  )}
                  component="img"
                  alt="Contemplative Reptile"
                  image={`${getContentPath(artist.logoUrlForMob)}`}
                  title={artist.name}
                />
                <CardContent style={{ padding: 10 }}>
                  <Grid>
                    <div style={{ display: 'flex' }}>
                      <Typography
                        // variant="h5"
                        // component="h2"
                        style={{
                          color: '#e1e1e1',
                          margin: mobileView ? '0px 10px' : '10px',
                          fontSize: mobileView ? '0.875rem' : '1.2rem',
                          fontWeight: 'bold'
                        }}
                      >
                        {ellipsis(artist.name, mobileView ? 10 : 40)}
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        color: '#e1e1e1',
                        margin: '0px 10px 0px',
                        fontSize: mobileView ? '0.75rem' : '1rem',
                      }}
                    >
                      {ellipsis(artist?.landingPageText, mobileView ? 14 : 50)}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        );
      })
    );
  };

  return (
    <Grid container className={classes.containerDiv}>
      <Grid item xs={12} className={classes.aboutDiv}>
        <div className={classes.aboutTitle}>
          {props.apiData.motherPortalHeader}
        </div>
        <div className={classes.aboutDesc}>
          {props.apiData.motherPortalDescription}
        </div>
      </Grid>
      <Grid item>
        <Grid
          container
          style={{ flexDirection: 'row', overflow: 'scroll' }}
          alignItems="center"
          spacing={mobileView ? 2 : 6}
        >
          {renderArtistList(props.apiData)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MotherPortalDashboard;
